import { atom } from "recoil";

export type CampaignSettings ={
  accountsDomains: {domain: string, accounts: string[]}[];
}

const initialState = {
  accountsDomains: [{domain: '', accounts: []}]
}

export const settingsState = atom<CampaignSettings>({
  key: 'SettingState',
  default: initialState
});




