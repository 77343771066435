import { FontWeight, RadioGroup, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { useRecoilState } from "recoil";
import { targetState } from "../../targetState/target.atom";
import { GenderType } from "../../../../../../createCampign.typs";


const genderValues = [
  { label: 'All', value: GenderType.All },
  { label: 'Men', value: GenderType.Men},
  { label: 'Women', value: GenderType.Women},
]

export const Gender = () => {
  const [ target, setTarget] = useRecoilState(targetState);
  
  const onCheckChanged = (value: string) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      gender: value as GenderType,
    }));
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Gender</Text>
      <RadioGroup
        items={genderValues}
        orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        onCheckChanged={onCheckChanged}
        checkedValue={target.gender}
      />
    </Card>
  );
}
