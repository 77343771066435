export enum Device {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Tablet = 'tablet',
  All = 'all',
  AllAndroid = 'allAndroid',
  AlliOS = 'alliOS',
}

export enum SearchProvider {
  Tonic = 'tonic',
  ExploreAds = 'exploreAds',
  AFS = 'googleDirect',
}

export enum Objective {
  Sales = 'sales',
  Leads = 'leads',
  Traffic = 'traffic',
}

export enum GenderType {
   All = 'all',
   Men = 'men',
   Women = 'women'
}

export enum BidStrategy {
  LowestCost = 'LOWEST_COST_WITHOUT_CAP',
  BidCap  = "LOWEST_COST_WITH_BID_CAP",
  CostCap = "COST_CAP",
}

export enum AdsOptimization {
  Conversions = 'OFFSITE_CONVERSIONS',
  Value = 'VALUE',
}

export enum TrafficOptimization {
  LandingPageViews = 'LANDING_PAGE_VIEWS',
  LinkClicks = 'LINK_CLICKS',
  Impressions = 'IMPRESSIONS',
  DailyUniqueReach = 'REACH',
}

export enum Scheduled {
  UploadNow = 'upload_now',
  Tomorrow00EST = 'tomorrow_00_est',
  Custom = 'custom',
}

export type Tonic =  {
  searchProvider: SearchProvider.Tonic;
  tonicCampaign: {id: string, name: string};
};

export type ExploreAds = {
  searchProvider: SearchProvider.ExploreAds;
  campaignOffer: string;
  identifier: string;
};

export type AFS = {
  searchProvider: SearchProvider.AFS
  identifier: string;
};

export type SearchCampaign = Tonic | ExploreAds | AFS;

export type FBInterest = {
  id: string;
  name: string;
  path: string[];
  audience_size_lower_bound?: number;
  audience_size_upper_bound?: number;
};




