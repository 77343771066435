/** @jsxImportSource @emotion/react */
import { DateInput, Dropdown } from "@ftbpro/mm-admin-ui-components";
import { useState } from "react";
import { TimeInput } from "../TimeInput/TimeInput";
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { targetState } from "../../../targetState/target.atom";


const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  }),
}



export const CustomDate = () => {
  const [target, setTarget] = useRecoilState(targetState);

  const onDateChange = (date: Date | null) => {
    if (date) {
      setTarget((prevTarget) => {
        const copyCustomDate = prevTarget.customDate ? { ...prevTarget.customDate } : { hour: '00', minutes: '00' };
        return {
          ...prevTarget,
          customDate: {
            ...copyCustomDate,
            date: date,
          }
        }
      });
    }
  }

  return (
    <div css={styles.container}>
      <DateInput
        selectedDate={target.customDate?.date ?target.customDate?.date : undefined}
        variables={{
          dropdownWidth: '169px',
        }}
        onDateSelect={onDateChange}
      />
      <TimeInput
        standartTime="Est"
      />
    </div>
  );
}
