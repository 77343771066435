import { atom, selector } from 'recoil';
import { ConfigurationsServiceConfigsProvider } from '../../../../../services/configurations/ConfigurationsServiceConfigsProvider';
import { SearchProvider } from './createCampign.typs';

export type CampaignListItem = {
  id: number,
  checksum: number,
  articleId: string,
  audiences: {
    audienceId: string,
    type: string,
    name: string,
  }
  defaultBid?: number,
  defaultDailyBudget?: number,
  maxBid?: number,
  bidStrategy: string,
  browser: string,
  conversionEvent: string,
  country: string,
  customPixel: string,
  device: string,
  dynamicCreative: boolean,
  freeTextName: string,
  gender: string,
  interest: {
    category: string,
    id: string,
    name: string,
  }
  isSearch: boolean,
  languageSet: boolean,
  minBid: number,
  objective: string,
  optimizationGoal: string,
  os: string,
  owner: string,
  pausedCampaign: boolean,
  roas: number | null,
  startOn: string,
  search: {
    searchProvider: SearchProvider,
    exploreAds: {
      campaignOffer: string,
      identifier: string,
    }
    googleDirect: {
      identifier: string,
    }
    tonic: {
      campaignId: string,
    }
  }
}

export const campaignsListState = atom({
  key: 'campaignsListState',
  default: [] as any[],
});

const campaignsDraftTriggeredInitialState = {
  triggered: false,
  loading: false,
  error: '',
};

export const campaignsDraftTriggeredState = atom({
  key: 'campaignsDraftTriggered',
  default: campaignsDraftTriggeredInitialState,
});

export const userConfiguration = selector({
  key: 'userConfigurationSelector',
  get: async () => {
    try {
      const response = await ConfigurationsServiceConfigsProvider.getConfigurations(true);
      return response.configs[0];
    } catch (error) {
      const errorMessage = 'Error fetching user configuration';
      throw new Error(errorMessage);
    }
  },
});

