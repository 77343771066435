import { HTTP_METHODS, mmNetworkService } from '../../core/network/mmServicesApiProvider';
import { CampaignListItem } from '../../pages/createCampaign/CreateCampaignWrapper/compnents/CreateCampaignDashboard/createCampaign.selector';

const REACT_APP_BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL;
const PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL;
const DRAFT = 'draft';

type submitCampaignsDraftPayload = {
  drafts: CampaignListItem[],
  articleGroup: string,
  DomainsAndAccounts: Record<string, string[]>
};

export class CampaignsServiceProvider {

  static getTonicCampaign() {
    const url = `${REACT_APP_BACKOFFICE_URL}/campaigns/tonic`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getCampaignData() {
    const pathname = window.location.pathname;
    const pathnameParts = pathname.split('/');
    const articleGroup = pathnameParts[2];
    const articleName = pathnameParts[3];
    const url = `${REACT_APP_BACKOFFICE_URL}/campaign/article-data/${articleGroup}/${articleName}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getFbInterestSearch(search: string) {
    const url = `${REACT_APP_BACKOFFICE_URL}/fb-interest-search/${search}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getFbInterestSuggestions(interests: { terms: string[] }) {
    const url = `${REACT_APP_BACKOFFICE_URL}/fb-interest-suggestions`;
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(interests)
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getDomainAccounts() {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/facebook/domain-accounts`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

        return mmNetworkService.fetch(url, configs);
    }

  static generateCampaignsDraft(campaignData: any) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/generate`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(campaignData),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static submitCampaignsDraft(payload: submitCampaignsDraftPayload) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/submit`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, configs);
  }
}


