import { useRecoilState } from "recoil";
import { settingsState } from "./settingsState/settings.atom";
import { AccountsDomain } from "./components/AccountsDomains/AccountsDomain";


export const Settings = () => {

  const [settings, setSettings] = useRecoilState(settingsState);
  const { accountsDomains } = settings;

  const onAddAccountsDomain = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      accountsDomains: [...prevSettings.accountsDomains, { domain: '', accounts: [] }]
    }))
  }

  const onDomainSelect = (selectedItem: string, index: number) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      accountsDomains: prevSettings.accountsDomains.map((accountDomain, i) =>
        i === index ? { accounts: [], domain: selectedItem } : accountDomain
      ),
    }));
  };


  const onAddAccount = (selectedItem: string, index: number) => {
    setSettings((prevSettings) => {
      const updatedAccountsDomains = prevSettings.accountsDomains.map((accountDomain, i) =>
        i === index
          ? {
            ...accountDomain,
            accounts: [...accountDomain.accounts, selectedItem],
          }
          : accountDomain
      );
      return {
        ...prevSettings,
        accountsDomains: updatedAccountsDomains,
      };
    });
  };


  const onRemoveAccount = (selectedItem: string, index: number) => {
    setSettings((prevSettings) => {
      const updatedAccountsDomains = prevSettings.accountsDomains.map((accountDomain, i) =>
        i === index
          ? {
            ...accountDomain,
            accounts: accountDomain.accounts.filter((account) => account !== selectedItem),
          }
          : accountDomain
      );
      return {
        ...prevSettings,
        accountsDomains: updatedAccountsDomains,
      };
    });
  };

  const onSelectAllAccounts = (selectedItems: string[], index: number) => {
    setSettings((prevSettings) => {
      const updatedAccountsDomains = prevSettings.accountsDomains.map((accountDomain, i) =>
        i === index
          ? {
            ...accountDomain,
            accounts: [...selectedItems],
          }
          : accountDomain
      );
      return {
        ...prevSettings,
        accountsDomains: updatedAccountsDomains,
      };
    });
  };

  const onRemoveAccountsDomain = (index: number) => {
    setSettings((prevSettings) => {
      const updatedAccountsDomains = [...prevSettings.accountsDomains];
      updatedAccountsDomains.splice(index, 1);
      return {
        ...prevSettings,
        accountsDomains: updatedAccountsDomains,
      }
    })
  }

  const isDomainExist = (domain: string, index: number) => {
    const domainExist = settings.accountsDomains.slice(0, index).some((accountDomain) => domain !== '' && accountDomain.domain === domain);
    return domainExist;
  }

  return (
    <>
      {
        accountsDomains.map((accountsDomain, index) => (
          <AccountsDomain
            onAddAccountsDomain={onAddAccountsDomain}
            index={index}
            onDomainSelect={onDomainSelect}
            onAddAccount={onAddAccount}
            onRemoveAccount={onRemoveAccount}
            onSelectAllAccounts={onSelectAllAccounts}
            selectedAccounts={accountsDomain.accounts}
            selectedDomain={accountsDomain.domain}
            onDeleteAccountsDomain={onRemoveAccountsDomain}
            showDeleteIcon={accountsDomains.length > 1}
            showAddIcon={index === accountsDomains.length - 1}
            key={index}
            isValidDomain={!isDomainExist(accountsDomain.domain, index)}
          />
        ))
      }
    </>
  );
}
