/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Dropdown, Input, TextButton } from "@ftbpro/mm-admin-ui-components";
import { css } from "@emotion/react";
import { Card } from "../../../Card/Card";


const styles = {
  card: {
    gap: 0
  },
  buttons: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    gap: "24px",
  }),
  input: {
    marginTop: "24px"
  },
}

export const CampaignsTemplate = () => {
  const [templateName, setTemplateName] = useState('');

  const onTemplateNameChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const inputElement = e.target as HTMLInputElement;
    setTemplateName(inputElement.value);
  }

  return (

    <Card style={styles.card}>
      <Dropdown
        placeholder={'Select'}
        items={[]}
        labelProps={{
          text: 'Choose Template',
        }}
        search
      />

      <div css={styles.buttons}>
        <TextButton content={'Share'} />
        <TextButton content={'Delete'} />
        <TextButton content={'Run'} />
      </div>

      <Input
        placeholder={'Template Name'}
        labelProps={{
          text: 'New Template',
        }}
        value={templateName}
        onChange={onTemplateNameChange}
        style={styles.input}
      />

      <div css={styles.buttons}>
        <TextButton content={'Save'} />
      </div>
    </Card>
  );
}
