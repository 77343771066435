import { atom } from "recoil";
import { createSelector } from "../../../../../../../core/utils/stateManager.utils";

interface CampaignErrorsState {
  errors: string[];
}

export const campainErrorsState = atom<CampaignErrorsState>({
  key: 'campaignErrorsState ',
  default: { errors: [] }
});
