import { Input } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState } from "recoil";
import { AFS, SearchCampaign } from "../../../../../../../../createCampign.typs";
import { targetState } from "../../../../targetState/target.atom";
import { useState } from "react";
import { isValidIdentifier } from "../../searchCampaign.validations";

export const AFSCampaign = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const { searchCampaign } = target;
  const [error, setError] = useState('');

  const onIdentifierAFSChange = (newIdentifier: string) => {
    if(!isValidIdentifier(newIdentifier)) {
      setError('Identifier field can\'t include spaces and underscores');
      return;
    }
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        ...searchCampaign,
        identifier: newIdentifier.slice(0, 15),
      } as SearchCampaign
    }));
  }

  return (
    <Input
      placeholder="Identifier"
      onChange={(e) => onIdentifierAFSChange((e.target as HTMLInputElement).value)}
      value={(searchCampaign as AFS)?.identifier}
      maxLength={15}
      error={error}
    />
  )

}
