/** @jsxImportSource @emotion/react */
import { CampaignsTemplate } from "./components/CampaignsTemplate/CampaignsTemplate";
import { TargetLanguage } from "./components/TargetLanguage/TargetLanguage";
import { Platform } from "./components/Platform/Platform";
import { SearchCampaignContainer } from './components/SearchCampaign/SearchCampaignContainer';
import { ObjectiveCampaign } from "./components/ObjectiveCampaign/ObjectiveCampaign";
import { TargetDevices } from "./components/TargetDevices/TargetDevices";
import { CampaignSetup } from "./components/CampaignSetup/CampaignSetup";
import { Gender } from "./components/Gender/Gender";
import { AgeRange } from "./components/AgeRange/AgeRange";
import { MoreOptions } from "./components/MoreOptions/MoreOptions";
import { BidAmount } from "./components/BidAmount/BidAmount";
import { DetailedTarget } from "./components/DetailedTarget/DetailedTarget";
import { TargetCountries } from "./components/TargetCountries/TargetCountries";
import { ScheduledCampaign } from "./components/ScheduledCampaign/ScheduledCampaign";



export const Target = () => {

  return (
    < >
      <CampaignsTemplate />
      <TargetLanguage />
      <Platform />
      <SearchCampaignContainer/>
      <ObjectiveCampaign/>
      <TargetDevices/>
      <CampaignSetup/>
      <Gender/>
      <AgeRange/>
      <MoreOptions/>
      <BidAmount/>
      <DetailedTarget/>
      <TargetCountries/>
      <ScheduledCampaign/>
    </>
  );
}
