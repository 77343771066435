import { Input } from "@ftbpro/mm-admin-ui-components"
import { ExploreAds, SearchCampaign } from "../../../../../../../../createCampign.typs";
import { useRecoilState } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { useState } from "react";
import { isValidIdentifier } from "../../searchCampaign.validations";

export const ExploreAdsCampaign = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const [error, setError] = useState('');
  const { searchCampaign } = target;


  const onCampaignOfferChange = (newOffer: string) => {

    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        ...searchCampaign,
        campaignOffer: newOffer,
      } as SearchCampaign
    }));
  }

  const onIdentifierChange = (newIdentifier: string) => {
    if(!isValidIdentifier(newIdentifier)) {
      setError('Identifier field can\'t include spaces and underscores');
      return;
    }
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        ...searchCampaign,
        identifier: newIdentifier.slice(0, 15),
      } as SearchCampaign
    }));
  }

  return (
    <>
      <Input
        placeholder={'Campaign Offer'}
        onChange={(e) => onCampaignOfferChange((e.target as HTMLInputElement).value)}
        value={(searchCampaign as ExploreAds)?.campaignOffer}
      />
      <Input
        placeholder="Identifier"
        onChange={(e) => onIdentifierChange((e.target as HTMLInputElement).value)}
        value={(searchCampaign as ExploreAds)?.identifier}
        maxLength={15}
        error={error}
      />
    </>
  )
}

