import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CreativeInsights } from './pages/creativeInsights/CreativeInsights';
import { GroupsTablePage } from './pages/groups/groupsTablePage/GroupsTablePage';
import { GroupsAdjustPage } from './pages/groups/groupAdjustPage/GroupAdjustPage';
import { GroupsProvider } from './pages/groups/groupsContext';
import { CreateCampaignWrapper } from './pages/createCampaign/CreateCampaignWrapper/CreateCampaignWrapper';
import { ConfigurationsProvider } from './pages/configurations/configurationsContext';
import { ConfigurationsTablePage } from './pages/configurations/configurationsTablePage/ConfigurationsTablePage';
import { ConfigurationAdjustPage } from './pages/configurations/configurationAdjustPage/ConfigurationAdjustPage';

function App() {
    const [isToken, setIsToken] = useState(false);
    useEffect(() => {
        const handleMessage = (event: any) => {
            if (event.data?.messageType === 'userDetails') {
                const isToken = !!event.data?.token;
                if (isToken) {
                    localStorage.setItem('auth_app_token', btoa(JSON.stringify(event.data.token)));
                    localStorage.setItem('auth_app_user', event.data?.user);
                    setIsToken(true);
                }
            }
        };
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);
    return (
        <>
            {!isToken ? <div>No Token Provided</div> :
                <Router>
                    <Routes>
                        <Route path="/create-campaigns/:group_name/:article_name/:language" element={<CreateCampaignWrapper />} />
                        <Route path="/creative-insights" element={<CreativeInsights />} />
                        <Route element={<GroupsProvider />}>
                            <Route path="groups" element={<GroupsTablePage />} />
                            <Route path="groups/new" element={<GroupsAdjustPage />} />
                            <Route path="groups/:id" element={<GroupsAdjustPage />} />
                        </Route>
                        <Route element={<ConfigurationsProvider/>}>
                            <Route path="campaign-settings" element={<ConfigurationsTablePage/>}/>
                            <Route path="campaign-settings/new" element={<ConfigurationAdjustPage/>}/>
                            <Route path="campaign-settings/:id" element={<ConfigurationAdjustPage/>}/>
                        </Route>
                    </Routes>
                </Router>
            }
        </>
    );
}

export default App;
