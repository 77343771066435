import { AdsOptimization, BidStrategy, Device, Objective, SearchCampaign, SearchProvider } from "../../createCampign.typs";
import { CampaignSettings } from "./components/Settings/settingsState/settings.atom";
import { isValidIdentifier } from "./components/Target/components/SearchCampaign/searchCampaign.validations";
import { CampaignTarget } from "./components/Target/targetState/target.atom";

export const validateCampaign = (campaignTarget: CampaignTarget, campaignSettings: CampaignSettings) => {
  let errors: string[] = [];

  if (campaignTarget.isSearchCampaign) {
    errors = validateSearchCampaign(campaignTarget.searchCampaign as SearchCampaign);
  }

  if (campaignTarget.countries.length === 0) {
    errors.push('Please select At least 1 Country');
  }

  const validateDevicesErrors = validateDevices(campaignTarget.devices);
  const validateAdsOptimizationErrors = validateAdsOptimization(campaignTarget.isSearchCampaign, campaignTarget.adsOptimization, campaignTarget.bidStrategy, campaignTarget.bidAmount);
  const validateConversionEventsErrors = validateConversionEvents(campaignTarget.conversionsEvent, campaignTarget.objective);
  const validateDomainAccountsErrors = validateAccountsDomains(campaignSettings.accountsDomains);

  errors = [
    ...errors,
    ...validateDevicesErrors,
    ...validateAdsOptimizationErrors,
    ...validateConversionEventsErrors,
    ...validateDomainAccountsErrors
  ];
  return errors;
}


const validateSearchCampaign = (searchCampaign: SearchCampaign) => {
  let errors: string[] = [];

  if (!searchCampaign?.searchProvider) {
    errors.push('Please select search provider');
    return errors;
  }

  if (searchCampaign.searchProvider === SearchProvider.Tonic && !searchCampaign.tonicCampaign) {
    errors.push('Please select a tonic campaign');
    return errors;
  }

  if (searchCampaign.searchProvider === SearchProvider.ExploreAds && !searchCampaign.campaignOffer) {
    errors.push('Please insert campaign offer');
  }

  if ((searchCampaign.searchProvider === SearchProvider.ExploreAds || searchCampaign.searchProvider === SearchProvider.AFS) && !searchCampaign.identifier) {
    errors.push('Please insert identifier');
  }

  if ((searchCampaign.searchProvider === 'exploreAds' || searchCampaign.searchProvider === 'googleDirect') && !isValidIdentifier(searchCampaign.identifier)) {
    errors.push('Identifier field can\'t include spaces and underscores');
  }
  return errors;
}

const validateDevices = (devices: { device: Device, browsersAndOS: string }[]) => {
  if (devices.length === 0) {
    return ['Please select At least 1 Device'];
  }

  const duplicateDevice = devices.some((item, index, arr) =>
    arr.filter((otherItem) => otherItem.device === item.device).length > 1
  );
  if (duplicateDevice) {
    return ['Duplicate Devices Selected'];
  }
  return [];
}

const validateAdsOptimization = (isSearchCampaign: boolean, adsOptimization?: string[], bidStrategy?: BidStrategy[], bidAmmount?: number[]) => {
  if (adsOptimization?.length === 0) {
    return ['Please select At least 1 Optimization'];
  }
  if (!adsOptimization?.includes(AdsOptimization.Value)) {
    if (bidStrategy?.length === 0) {
      return ['Please select At least 1 bid strategy'];
    }
  } else if (!isSearchCampaign && bidAmmount?.length === 0) {
    return ['Please select At least 1 bid amount'];
  }
  return [];
}

const validateConversionEvents = (conversionEvents: string[], campaignObjective: Objective) => {
  if (campaignObjective !== Objective.Traffic && conversionEvents.length === 0) {
    return ['Please select At least 1 conversionEvents'];
  }
  return [];
}

const validateAccountsDomains = (accountsDomainList: { domain: string, accounts: string[] }[]) => {
  let errors: string[] = [];

  const allDomainsAreEmpty = accountsDomainList.every((domainAccount) => domainAccount.domain.length === 0);

  if(accountsDomainList.length === 0  || allDomainsAreEmpty) {
    return ['Please select At least 1 domain'];
  }

  accountsDomainList.forEach((domainAccount) => {
    if (domainAccount.accounts.length === 0 && domainAccount.domain) {
      errors.push(`Please select At least 1 account for ${domainAccount.domain}`);
    }
  });

  const duplicateDomains = accountsDomainList.some((item, index, arr) =>
    arr.filter((otherItem) => otherItem.domain === item.domain).length > 1
  );

  if (duplicateDomains) {
    errors.push('Duplicate Domains Selected');
  }

  return errors;
}
