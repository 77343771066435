import moment_tz from 'moment-timezone';
import { Device, FBInterest, GenderType, Scheduled, SearchCampaign, SearchProvider } from "../../createCampign.typs";
import { CampaignSettings } from "./components/Settings/settingsState/settings.atom";
import { CampaignTarget } from "./components/Target/targetState/target.atom";
import { generateChecksum } from '../../../../../../../core/utils/utils';

export const formatCampaignToServer = (campaignTarget: CampaignTarget, campaignSettings: CampaignSettings, articleId: string) => {
  return {
    targeting: {
      devicesAndOS: getDevicesAndOsFormat(campaignTarget.devices),
      countries: campaignTarget.countries,
      optimizationGoals: campaignTarget.adsOptimization,
      bids: campaignTarget.bidAmount,
      conversionEvents: campaignTarget.conversionsEvent,
      roas: campaignTarget.roas,
      ages: campaignTarget.ages,
      bidStrategies: campaignTarget.bidStrategy,
      genders: getGenderFormat(campaignTarget.gender),
      interests: getInterestsFormat(campaignTarget.interests),
      audiences: getAudiencesFormat(campaignTarget.customAudiences?.website, campaignTarget.customAudiences?.lookalike),
      browsers: getBrowserFormat(campaignTarget.devices),
      isLanguageSet: campaignTarget.isLanguageSet,
    },
    metadata: {
      articleId,
      startOn: getStartOnDate(campaignTarget.scheduledCampaign, campaignTarget.customDate),
      objective: campaignTarget.objective,
      pausedCampaign: campaignTarget.isPausedCampaign,
      dynamicCreative: campaignTarget.isDynamicCreative,
      isSearch: campaignTarget.isSearchCampaign,
      search: campaignTarget.isSearchCampaign ? getSearchCampaignFormat(campaignTarget.searchCampaign as SearchCampaign) : undefined,
      customPixel: campaignTarget.customPixel,
    }
  }
}


const getDevicesAndOsFormat = (devices: { device: string, browsersAndOS: string }[]) => {
  return devices.map(device => {
    if (device.device === Device.Desktop) {
      return {
        device: device.device,
        os: 'all'
      }
    }
    if (device.device === Device.AllAndroid) {
      return {
        device: 'all',
        os: 'android'
      }
    }
    return {
      device: device.device,
      os: device.browsersAndOS,
    }
  })
}

const getBrowserFormat = (devices: { device: string, browsersAndOS: string }[]) => {
  const desktopDevice = devices.filter(device => device.device === Device.Desktop);
  if (desktopDevice.length > 0 && desktopDevice[0].browsersAndOS !== 'all') {
    return [desktopDevice[0].browsersAndOS];
  }
}

const getGenderFormat = (gender?: GenderType) => {
  switch (gender) {
    case GenderType.All:
      return [GenderType.Men, GenderType.Women]
    case GenderType.Men:
      return [GenderType.Men]
    case GenderType.Women:
      return [GenderType.Women]
    default:
      return [];
  }
}

const getInterestsFormat = (interests?: FBInterest[]) => {
  return interests?.map(interest => {
    return {
      name: interest.name,
      id: interest.id,
      category: interest.path[0]
    }
  })
}

const getSearchCampaignFormat = (searchCampaign: SearchCampaign) => {
  switch (searchCampaign.searchProvider) {
    case SearchProvider.Tonic:
      return {
        searchProvider: SearchProvider.Tonic,
        tonic: {
          campaignId: searchCampaign.tonicCampaign.id.toString()
        }
      }
    case SearchProvider.ExploreAds:
      return {
        searchProvider: SearchProvider.ExploreAds,
        exploreAds: {
          campaignOffer: searchCampaign.campaignOffer,
          identifier: searchCampaign.identifier
        }
      }
    case SearchProvider.AFS:
      return {
        searchProvider: SearchProvider.AFS,
        googleDirect: {
          identifier: searchCampaign.identifier
        }
      }
    default:
      return {}
  }
}

const getAudiencesFormat = (websiteAudiences?: { id: string, name: string }[], lookalikeAudiences?: { id: string, name: string }[]) => {
  const websites = websiteAudiences?.map((website) => { return { id: website.id, name: website.name, type: 'website' } }) || [];
  const lookalikes = lookalikeAudiences?.map((lookalike) => { return { id: lookalike.id, name: lookalike.name, type: 'lookalike' } }) || [];
  return [
    ...websites,
    ...lookalikes
  ]
}

function getTomorrow00EstDate() {
  return moment_tz.tz('America/New_York').add(1, 'day').format('YYYY-MM-DD') + ' 00:00';
}

function getCustomDate(customDate?: { date: Date | null, hour: string, minutes: string }) {
  if (!customDate?.date || !customDate.hour || !customDate.minutes) {
    return null;
  }
  return moment_tz(customDate.date).format('YYYY-MM-DD') + ' ' + customDate.hour + ':' + customDate.minutes;
}

function getStartOnDate(scheduledValue: Scheduled, customDate?: { date: Date | null, hour: string, minutes: string }) {
  switch (scheduledValue) {
    case Scheduled.Custom:
      return getCustomDate(customDate);
    case Scheduled.Tomorrow00EST:
      return getTomorrow00EstDate();
    default:
      return null;
  }
}

export const formatCampaignsDraftsForClient = (drafts: any[], existingCampaigns: any[]) => {
  const existingChecksums = new Set(existingCampaigns.map((item) => item.checksum));
  const lastId = existingCampaigns.length > 0 ? Math.max(...existingCampaigns.map((item) => item.id)) : 0;
  const uniqueDrafts = drafts
    .map((item: any, index: number) => ({
      ...item,
      checksum: generateChecksum(item),
      id: lastId + index + 1
    }))
    .filter((item: any) => !existingChecksums.has(item.checksum));
  return [...existingCampaigns, ...uniqueDrafts];
};

