/** @jsxImportSource @emotion/react */
import { Checkbox, FontWeight, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { css } from '@emotion/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { targetState } from '../../targetState/target.atom';
import { campaignsDraftTriggeredState } from '../../../../../../createCampaign.selector';

const styles = {
  checkboxWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  })
}

export const MoreOptions = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { isDynamicCreative, isPausedCampaign } = target
  const { triggered: applyDraftTriggered } = useRecoilValue(campaignsDraftTriggeredState);


  const onDynamicCreativeChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isDynamicCreative: !isDynamicCreative
    }));
  }

  const onPausedCampaignChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isPausedCampaign: !isPausedCampaign
    }));
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>More Options</Text>

      <div css={styles.checkboxWrapper}>
        <Checkbox
          checked={isDynamicCreative}
          onChange={onDynamicCreativeChange}
          disabled={applyDraftTriggered}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M} >Dynamic Creative</Text>
        </Checkbox>

        <Checkbox
          checked={isPausedCampaign}
          onChange={onPausedCampaignChange}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Create paused campaign</Text>
        </Checkbox>
      </div>
    </Card>
  );
}
