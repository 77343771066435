/** @jsxImportSource @emotion/react */
import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { Card, Input, InputType, Text } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';

const CARD_TITLE = 'Settings';
const DEFAULT_DAILY_BUDGET_LABEL = 'Default Daily Budget';
const DEFAULT_DAILY_BUDGET_PLACEHOLDER = 'Enter Default Daily Budget';
const DEFAULT_BID_LABEL = 'Default Bid';
const DEFAULT_BID_PLACEHOLDER = 'Enter Default Bid';
const MAX_BID_LABEL = 'Max Bid';
const MAX_BID_PLACEHOLDER = 'Enter Max Bid';

export const styles = {
  card: {
    width: '100%',
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
    overflow: 'wrap',
    boxShadow: 'none',
  },
  wrapper: css({
    display: 'grid',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '5px',
    rowGap: '20px',
  }),
};

interface CampaignSettingsProps {
  defaultDailyBudget: string;
  setDefaultDailyBudget: Dispatch<SetStateAction<string>>;
  defaultBid: string;
  setDefaultBid: Dispatch<SetStateAction<string>>;
  maxBid: string;
  setMaxBid: Dispatch<SetStateAction<string>>;
}

export const CampaignSettings = (props: CampaignSettingsProps) => {
  const {
    defaultDailyBudget,
    setDefaultDailyBudget,
    defaultBid,
    setDefaultBid, maxBid,
    setMaxBid
  } = props;

  const onDefaultDailyBudgetChange = (e: SyntheticEvent, data?: string) => {
    const newDefaultDailyBudget = data || '';
    setDefaultDailyBudget(newDefaultDailyBudget);
  };
  const onDefaultBidChange = (e: SyntheticEvent, data?: string) => {
    const newsDefaultBid = data || '';
    setDefaultBid(newsDefaultBid);
  };
  const onMaxBidChange = (e: SyntheticEvent, data?: string) => {
    const newMaxBid = data || '';
    setMaxBid(newMaxBid);
  };

  return (
    <Card
      title={CARD_TITLE}
      textType={Text.TEXT_TYPES.TITLE_S}
      variables={{
        cardHeight: '422px',
      }}
      style={styles.card}
    >
      <div css={styles.wrapper}>
        <Input
          value={defaultDailyBudget}
          onChange={onDefaultDailyBudgetChange}
          labelProps={{ text: DEFAULT_DAILY_BUDGET_LABEL }}
          type={InputType.Number}
          placeholder={DEFAULT_DAILY_BUDGET_PLACEHOLDER}
        />
        <Input
          value={defaultBid}
          onChange={onDefaultBidChange}
          labelProps={{ text: DEFAULT_BID_LABEL }}
          type={InputType.Number}
          placeholder={DEFAULT_BID_PLACEHOLDER}
        />
        <Input
          value={maxBid}
          onChange={onMaxBidChange}
          labelProps={{ text: MAX_BID_LABEL }}
          type={InputType.Number}
          placeholder={MAX_BID_PLACEHOLDER}
        />
      </div>
    </Card>
  );
};
