/** @jsxImportSource @emotion/react */
import { Dropdown, DropdownListItemProps, TextButton } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../../../Card/Card";
import { AddFillIcon, DeleteIcon } from "@ftbpro/mm-admin-assets";
import { css } from "@emotion/react";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { Device } from "../../../../../../../../createCampign.typs";

interface SelectDeviceProps {
  selectedDevice: string;
  onDeviceChange: (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps, index: number) => void;
  selectedBrowsersAndOS: string;
  onBrowsersAndOSChange:(e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps, index: number) => void;
  onDeleteDevice: (deviceIndex: number) => void;
  onAddDevice: any;
  deviceIndex: number;
  showAddDeviceIcon: boolean;
  showDeleteDeviceIcon: boolean;
  error: string;
}

const deviceItems = [
  { value: Device.All, content: 'All' },
  { value: Device.Desktop, content: 'Desktop' },
  { value: Device.Tablet, content: 'Tablet' },
  { value: Device.Mobile, content: 'Mobile' },
  { value: Device.AllAndroid, content: 'All Androaid' },
  { value: Device.AlliOS, content: 'all iOS' },
]


const browsersAndOSItems: Record<Device, { content: string; value: string }[]> = {
  [Device.All]: [{ content: 'All', value: 'all' }],
  [Device.Desktop]: [{ content: 'All', value: 'all' }, { content: 'Chrome', value: 'chrome' }, { content: 'Edge', value: 'edge' }],
  [Device.Tablet]: [{ content: 'All', value: 'all' }, { content: 'Android', value: 'android' }, { content: 'iOS', value: 'ios' }],
  [Device.Mobile]: [{ content: 'All', value: 'all' }, { content: 'Android', value: 'android' }, { content: 'iOS', value: 'ios' }],
  [Device.AllAndroid]: [{ content: 'All', value: 'all' }],
  [Device.AlliOS]: [{ content: 'All', value: 'all' }],
}

const styles = {
  buttons: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  })
}

export const SelectDevice = (props: SelectDeviceProps) => {
  const { 
    selectedDevice,
    onDeviceChange,
    selectedBrowsersAndOS,
    onBrowsersAndOSChange,
    onDeleteDevice,
    onAddDevice,
    deviceIndex,
    showAddDeviceIcon,
    showDeleteDeviceIcon,
    error,
  } = props;

  const showAddIcon = showAddDeviceIcon && deviceIndex < deviceItems.length - 1;

  return (
    <Card>
      <Dropdown
        items={deviceItems}
        labelProps={{
          text: 'Devices',
        }}
        error={error}
        selectedValue={selectedDevice}
        onSelectedChange={(e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => onDeviceChange(e, selectedItem, deviceIndex)}
      />

      <Dropdown
        items={browsersAndOSItems[selectedDevice as Device]}
        labelProps={{
          text: 'Browsers & OS',
        }}
        selectedValue={selectedBrowsersAndOS}
        onSelectedChange={(e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => onBrowsersAndOSChange(e, selectedItem, deviceIndex)}
      />

      <div css={styles.buttons}>  
        {showDeleteDeviceIcon && <TextButton content={'Delete'} onClick={ () => onDeleteDevice(deviceIndex)} icon={DeleteIcon} />}
        {showAddIcon &&  <TextButton content={'Add Device'} onClick={onAddDevice} icon={AddFillIcon} />}
      </div>
    </Card>
  );
}
