/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SNACK_BAR_STATUSES, SnackBar } from "@ftbpro/mm-admin-ui-components";
import { useRecoilValue } from "recoil";
import { campainErrorsState } from "./createCampaignErrors.atom";


const styles = {
  errorContainer: css({
    position: "absolute",
    top: 0,
    left: 0,
  }),
}

export const CreateCampaignErrors = () => {
  const { errors } = useRecoilValue(campainErrorsState);

  return (
    <div css={styles.errorContainer}>
      {
        errors.map((error, index) => {
          return <SnackBar text={error} status={SNACK_BAR_STATUSES.ERROR} stayOpen key={index} onClose={() => {}}/>
        })
      }
    </div>
  );
}
