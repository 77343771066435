/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FontWeight, Text } from '@ftbpro/mm-admin-ui-components';
import { SettingsAndTarget } from './components/SettingsAndTarget/SettingsAndTarget';
import { CampaignsListContainer } from './components/CampaignsListContainer/CampaignsListContainer';
import { CreateCampaignErrors } from './components/CreateCampaignErrors/CreateCampaignErrors';
import { useParams } from 'react-router-dom';



const styles = {
  container: css({
    marginLeft: "3px",
    position: "relative"
  }),
  title: css({
    FontWeight: "700",
    padding: "20px",
    backgroundColor: "#ffffff",
    marginBottom: "3px",
  }),
  contentContainer: css({
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  }),
  errorContainer: css({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2000,
  }),
}

export const CreateCampaignDashboard = () => {
  const { article_name } = useParams();

  return (
    <div css={styles.container}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_XXL}
        fontWeight={FontWeight.Bold}
        style={styles.title}
      >{`Create New Campaigns in Article: ${article_name}`}
      </Text>
      <div css={styles.contentContainer}>
        <SettingsAndTarget />
        <CampaignsListContainer />
      </div>
      <CreateCampaignErrors />
    </div>
  );
}
