import { Checkbox, Text } from "@ftbpro/mm-admin-ui-components"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "../../../Card/Card"
import { useRecoilState } from "recoil"
import { targetState } from "../../targetState/target.atom"


export const TargetLanguage = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const isLanguageSet = target.isLanguageSet;
  const { language } = useParams();


  useEffect(() => {
    if (language) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        language: language,
      }));
    }
  }, []);

  const onCheckChanged = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isLanguageSet: !isLanguageSet,
    }));
  }

  return (
    <Card>
      <Checkbox
        checked={isLanguageSet}
        onChange={onCheckChanged}
      >
        <Text>{`Target Language: ${language}`}</Text>
      </Checkbox>
    </Card>
  )
}
