import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { Tonic } from "../../../../../../../../createCampign.typs";
import { useEffect, useState } from "react";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { tonicCampaigns } from "./tonicCampaign.selector";



export const TonicCampaign = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { searchCampaign } = target;
  const tonicCampaignsLoadable = useRecoilValueLoadable(tonicCampaigns);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tonicCampaignsList, setTonicCampaignsList] = useState<any[]>([]);

  useEffect(() => {
    switch (tonicCampaignsLoadable.state) {
      case 'loading':
        setLoading(true);
        setError('');
        break;
      case 'hasError':
        setLoading(false);
        setError(tonicCampaignsLoadable.contents.message || 'An error occurred');
        break;
      case 'hasValue':
        setLoading(false);
        setError('');
        setTonicCampaignsList(tonicCampaignsLoadable.contents);
        break;
      default:
        break;
    }
  }, [tonicCampaignsLoadable.state, tonicCampaignsLoadable.contents]);

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      searchCampaign: {
        ...searchCampaign,
        tonicCampaign: {id: selectedItem.value, name: selectedItem.content},
      } as Tonic
    }));
  }

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const stringQuery = `${query}`;
    const content = item.content as string;
    const value = item.value as string;
    return content?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase()) || value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };
  
  return (
    <Dropdown
      placeholder={'Tonic Campaign'}
      items={tonicCampaignsList}
      loading={loading}
      onSelectedChange={onSelectedChange}
      search
      selectedValue={(searchCampaign as Tonic)?.tonicCampaign?.name}
      error={error}
      searchPredicate={searchPredicate}
    />
  )
}
