/** @jsxImportSource @emotion/react */
import { Loader, NoData, RowData, SNACK_BAR_STATUSES, SnackBar, TableNew } from '@ftbpro/mm-admin-ui-components';
import React, { useMemo } from 'react';
import { getUserDetails } from '../../../../../../../../../core/utils/user.utils';
import { getColumnsDefinition } from './CampaignsListTableColumnsDefinition';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { tonicCampaigns } from '../../../SettingsAndTarget/components/Target/components/SearchCampaign/components/TonicCampaign/tonicCampaign.selector';
import { campaignsListState, campaignsDraftTriggeredState, userConfiguration } from '../../../../createCampaign.selector';
import { css } from '@emotion/react';
import { generateChecksum } from '../../../../../../../../../core/utils/utils';
import { BulkOperations } from './components/BulkOperations/BulkOperations';


const styles = {
  wrapper: css({
    padding: '24px',
  }),
  loaderContainer: css({
    width: '100%',
    height: '191px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  noDataWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#FFF',
  }),
  tableWrapper: css({
    position: 'relative',
  }),
  snackBar: css({
    position: 'absolute',
    top: '-55px',
    right: '0px',
    width: '100%',
  }),
};

export const CampaignsListTable = () => {
  const tonicCampaignsList = useRecoilValueLoadable(tonicCampaigns).contents;
  const userDefaultConfiguration = useRecoilValueLoadable(userConfiguration).contents;
  const [triggeredState, setTriggeredState] = useRecoilState(campaignsDraftTriggeredState);
  const { loading, triggered, error } = triggeredState;
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsListState);
  const userDetails = useMemo(() => getUserDetails(), []);
  const isExternalUser = userDetails['roles'].includes('externalFBMedia');
  const editableData: any = campaignsList.map(item => ({ ...item }));
  const [selectedCampaignsIds, setSelectedCampaignsIds] = React.useState<number[]>([]);

  const onDeleteClick = (id: number) => {
    setCampaignsList((prevList) => prevList.filter((row) => row.id !== id));
  };

  const excludeFields = (data: any, fields: string[]) => {
    const newData = { ...data };
    fields.forEach((field) => delete newData[field]);
    return newData;
  };

  const updateCellValue = (newValue: string | number, field: string, data: RowData) => {
    const updatedRow = newValue
      ? { ...data, [field]: field === 'freeTextName' ? newValue : Number(newValue) }
      : data;

    const dataForChecksum = excludeFields(updatedRow, ['id', 'checksum']);
    const newChecksum = generateChecksum(dataForChecksum);
    const updatedRowWithChecksum = { ...updatedRow, checksum: newChecksum };
    setCampaignsList((prevList) => prevList.map((row) => row.id === data.id ? updatedRowWithChecksum : row));
  };

  const onCellValueChanged = (params: any) => {
    updateCellValue(params.newValue, params.colDef.field, params.data);
  };

  const onSelectedCheckboxesChanged = (data: RowData[]) => {
    setSelectedCampaignsIds(data.map(campaign => campaign.id));
  };

  const columnsDefinition = getColumnsDefinition({ isExternalUser, tonicCampaignsList, onDeleteClick, onCellValueChanged, userDefaultConfiguration });

  return (
    <>
      {triggered ? (
        <div css={styles.wrapper}>
          {error &&
           <div css={styles.snackBar}>
             <SnackBar
               text={error}
               status={SNACK_BAR_STATUSES.ERROR}
               stayOpen={false}
               onClose={() => {
                 setTriggeredState((prevState) => ({
                   ...prevState,
                   error: '',
                 }));
               }}
             />
           </div>}
      {loading ? (
        <div css={styles.loaderContainer}>
          <Loader size={Loader.LOADER_SIZES.HUGH}/>
        </div>
      ) : campaignsList.length === 0 ? (
        <div css={styles.noDataWrapper}><NoData/></div>
      ) : (
        <div css={styles.tableWrapper}>
          <TableNew
            data={editableData}
            columnsDefinition={columnsDefinition}
            defaultColDef={{ minWidth: 130 }}
            pagination
            onSelectedCheckboxesChanged={onSelectedCheckboxesChanged}
            additionalHeaderComponent={(
              <BulkOperations
                selectedCampaignsIds={selectedCampaignsIds}
                updateCellValue={updateCellValue}
                onDeleteClick={onDeleteClick}
                userDefaultConfiguration={userDefaultConfiguration}
              />)}
            suppressRowClickSelection
          />
        </div>
      )}
    </div>) : null}</>
  );
};
