/** @jsxImportSource @emotion/react */
import { Text, FontWeight } from '@ftbpro/mm-admin-ui-components';
import { Card } from "../../../Card/Card";
import { CustomSetup } from "./components/CustomSetup/CustomSetup";
import { ConversionsEvent } from "./components/ConversionsEvent/ConversionsEvent";
import { DeliveryAdsOptimization } from "./components/DeliveryAdsOptimization/DeliveryAdsOptimization";
import { CampaignBidStrategy } from "./components/CampaignBidStrategy/CampaignBidStrategy";
import { RoasGoal } from "./components/RoasGoal/RoasGoal";

export const CampaignSetup = () => {


  return (
    <Card >
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Campaign Setup</Text>
      <DeliveryAdsOptimization />
      <RoasGoal />
      <ConversionsEvent />
      <CampaignBidStrategy />
      <CustomSetup />
    </Card>
  );
}
