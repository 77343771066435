import { RecoilRoot } from "recoil";
import { CreateCampaignDashboard } from "./compnents/CreateCampaignDashboard/CreateCampaignDashboard";

export const CreateCampaignWrapper = () => {
  return (
    <RecoilRoot>
      <CreateCampaignDashboard />
    </RecoilRoot>
  );
}
