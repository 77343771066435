import { atom } from "recoil";
import { AdsOptimization,  BidStrategy,  Device, FBInterest, GenderType, Objective, Scheduled, SearchCampaign, TrafficOptimization } from "../../../../../createCampign.typs";

export type CampaignTarget = {
  isLanguageSet: boolean;
  objective: Objective;
  moreOptions: object;
  isSearchCampaign: boolean;
  searchCampaign?: SearchCampaign;
  bidAmount: number[];
  language: string;
  countries: string[];
  devices: { device: Device, browsersAndOS: string }[];
  adsOptimization: AdsOptimization[] | TrafficOptimization[];
  conversionsEvent: string[];
  bidStrategy?: BidStrategy[];
  customAudiences?: {
    website?: {id: string, name: string}[];
    lookalike?: {id: string, name: string}[];
  }
  customPixel?: string;
  gender?: GenderType;
  ages: string[];
  isDynamicCreative: boolean;
  isPausedCampaign: boolean;
  interests?: FBInterest[];
  allInterestSearch?: FBInterest[];
  scheduledCampaign: Scheduled;
  customDate?: {
    date: Date | null;
    hour: string;
    minutes: string;
  },
  roas?: number[];
}

const initialState = {
  isLanguageSet: true,
  objective: Objective.Sales,
  adsOptimization: [AdsOptimization.Conversions],
  bidAmount: [0.5],
  devices: [{ device: Device.All, browsersAndOS: 'all' }],
  isDynamicCreative: false,
  isPausedCampaign: false,
  scheduledCampaign: Scheduled.Tomorrow00EST,
  conversionsEvent: [] as string[],
  countries: [] as string[],
  gender: GenderType.All,
}

export const targetState = atom<CampaignTarget>({
  key: 'targetState',
  default: initialState as CampaignTarget
});
