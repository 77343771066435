import { FontWeight, RadioGroup, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { useRecoilState } from "recoil";
import { targetState } from "../../targetState/target.atom";
import { Objective } from "../../../../../../createCampign.typs";

const OBJECTIVE_VALUES = [
  {
    label: 'Sales',
    value: Objective.Sales,
  },
  {
    label: 'Traffic',
    value: Objective.Traffic,
  },
  {
    label: 'Leads',
    value: Objective.Leads,
  },
];

const SEARCH_OBJECTIVE_VALUES = [
  {
    label: 'Sales',
    value: Objective.Sales,
  },
  {
    label: 'Leads',
    value: Objective.Leads,
  },
]


export const ObjectiveCampaign = () => {
  const [target, setTarget] = useRecoilState(targetState);

  const onCheckChanged = (value: string) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      objective: value as Objective,
      adsOptimization: [],
      conversionsEvent: [],
    }));
  }

  return (
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Objective</Text>
      <RadioGroup
        items={target.isSearchCampaign ? SEARCH_OBJECTIVE_VALUES : OBJECTIVE_VALUES}
        orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        onCheckChanged={onCheckChanged}
        defaultCheckedValue={target.objective}
      />
    </Card>
  );
}
