import { CampaignListItem } from '../../createCampaign.selector';


export const validateCampaigns = (campaignsList: CampaignListItem[]) => {
  let errors: string[] = [];
  const checksumMap = new Map();

  if (campaignsList.length === 0) {
    errors.push('Please add at least 1 campaign');
    return errors;
  }
  console.log('campaignsList', campaignsList);
  campaignsList.forEach((campaign, index) => {
    const campaignErrors = [];
    if (campaign?.freeTextName && !validateFreeTextName(campaign.freeTextName)) {
      campaignErrors.push('Campaign name should be less than 10 characters and should not contain special characters');
    }
    if (campaign?.defaultBid && !validateDefaultBid(campaign.defaultBid)) {
      campaignErrors.push('Default bid should be greater than 0.01');
    }
    if (campaign?.defaultDailyBudget && !validateDefaultDailyBudget(campaign.defaultDailyBudget)) {
      campaignErrors.push('Daily budget should be less than 300');
    }
    if (campaignErrors.length > 0) {
      errors.push(`Campaign ${index + 1}: ${campaignErrors.join(', ')}`);
    }

    const { checksum } = campaign;
    if (checksumMap.has(checksum)) {
      checksumMap.get(checksum).push(index);
    } else {
      checksumMap.set(checksum, [index]);
    }
  });

  const duplicateGroups: number[][] = Array.from(checksumMap.values()).filter(
    (indexes) => indexes.length > 1
  );

  if (duplicateGroups.length > 0) {
    duplicateGroups.forEach((group) => {
      errors.push(`Duplicate Campaigns: ${group.map((index) => index + 1).join(', ')}`);
    });
  }
  return errors;
};

export const validateFreeTextName = (name: string) => {
  const regex = /^[a-zA-Z\d]+$/g;
  const found = name.match(regex);
  return !(found == null || name.length > 10);
};

export const validateDefaultDailyBudget = (dailyBudget: number) => {
  return dailyBudget > 300;
};

export const validateDefaultBid = (defaultBid: number) => {
  return defaultBid > 0.01;
};
