import { Dropdown, DropdownListItemProps } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { useEffect } from "react";
import { BidStrategy } from "../../../../../../../../createCampign.typs";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";


const bidStrategyItems = [
  { content: 'Lowest Cost', value: BidStrategy.LowestCost },
  { content: 'Bid Cap', value: BidStrategy.BidCap },
  { content: 'Cost Cap', value: BidStrategy.CostCap },
]

export const CampaignBidStrategy = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const { isSearchCampaign, bidStrategy } = target;

  useEffect(() => {
    if (!bidStrategy) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        bidStrategy: isSearchCampaign ? [BidStrategy.CostCap] : [BidStrategy.BidCap],
      }));
    }
  }, [isSearchCampaign]);

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      const copyBidStrategy = prevTarget.bidStrategy ? [...prevTarget.bidStrategy] : [];
      return {
        ...prevTarget,
        bidStrategy: [...copyBidStrategy, selectedItem.value as BidStrategy]
      }
    });
  }

  const onBidStrategyDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        bidStrategy: prevTarget.bidStrategy?.filter((bidStrategy) => bidStrategy !== selectedItem.value)
      }
    });
  }

  const onBidStrategySelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        bidStrategy: selectedItems.map((item) => item.value as BidStrategy)
      }
    });
  }

  return (
    <Dropdown
      items={bidStrategyItems}
      selectAllLabel={'Select All'}
      multiSelection
      showCheckboxes
      placeholder="Bid Strategy"
      selectedValues={target.bidStrategy as string[]}
      onSelectedChange={onSelectedChange}
      onValueRemove={onBidStrategyDelete}
      onSelectAllChange={onBidStrategySelectAll}
    />
  )
}
