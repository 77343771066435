import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';

export const ErrorsTooltipContent = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.map((error, index) => (
        <Text key={index} type={Text.TEXT_TYPES.PARAGRAPH_M}>{`- ${error}`}</Text>
      ))}
    </>
  );
};
