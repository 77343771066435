import { SearchProvider } from '../../../../createCampign.typs';
import { ActionsBar, ButtonType, IconButton } from '@ftbpro/mm-admin-ui-components';
import { DeleteIcon } from '@ftbpro/mm-admin-assets';
import { Configuration } from '../../../../../../../../configurations/configurations.types';
import { validateDefaultBid, validateDefaultDailyBudget, validateFreeTextName } from '../../campaignsList.validations';

interface getColumnsDefinitionProps {
  isExternalUser: boolean;
  tonicCampaignsList: { value: string, content: string }[];
  onDeleteClick: (id: number) => void;
  onCellValueChanged: (params: any) => void;
  userDefaultConfiguration: Configuration;
}

export const getColumnsDefinition = ({ isExternalUser, tonicCampaignsList, onDeleteClick, onCellValueChanged, userDefaultConfiguration }: getColumnsDefinitionProps) => [
  {
    headerName: '',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: false,
    checkboxSelection: true,
    editable: false,
    filter: false,
    width: 20,
  },
  {
    headerName: '',
    sortable: false,
    cellRenderer: (params: any) =>
      <ActionsBar
        absolutelyPositioned={true}
        elements={[
          {
            tooltipContent: 'Delete',
            render: () => (
              <IconButton
                type={ButtonType.Secondary}
                icon={DeleteIcon}
                onClick={() => onDeleteClick(params.data.id as number)}
              />
            ),
          }]}
      />,
  },
  {
    headerName: 'Name',
    field: 'freeTextName',
    filter: 'agTextColumnFilter',
    width: 180,
    editable: true,
    cellStyle: function (params: any) {
      if (params.value) {
        if (!validateFreeTextName(params.value)) {
          return { color: 'white', background: '#ff4c6a' };
        } else {
          return { color: 'inherit', background: 'inherit' };
        }
      }
    },
  },
  {
    headerName: 'Device',
    field: 'device',
    width: 90,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'OS',
    field: 'os',
    filter: 'agTextColumnFilter',
    width: 90,
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'Browsers',
    field: 'browser',
    width: 90,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'Country',
    field: 'country',
    width: 70,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'Objective',
    field: 'objective',
    width: 100,
    filter: 'agTextColumnFilter',
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'CPC',
    field: 'defaultBid',
    filter: 'agNumberColumnFilter',
    width: 90,
    editable: (params: any) => !isExternalUser && !(params.data.bidStrategy === 'LOWEST_COST_WITHOUT_CAP'),
    valueFormatter: (params: any) => {
      if (params.data.bidStrategy === 'LOWEST_COST_WITHOUT_CAP') {
        return 0;
      }
      return params.value ? params.value : userDefaultConfiguration.defaultBid;
    },
    cellStyle: (params: any) => {
      if (params.value && !validateDefaultBid(params.value)) {
        return { color: 'white', background: '#ff4c6a' };
      } else {
        return { color: 'inherit', background: 'inherit' };
      }
    },
    filterParams: { newRowsAction: 'keep' },
    onCellValueChanged: onCellValueChanged,
  },
  {
    headerName: 'Max Bid',
    field: 'maxBid',
    filter: 'agNumberColumnFilter',
    width: 100,
    editable: false,
    valueFormatter: (params: any) => params.value ? params.value : userDefaultConfiguration.maxBid,
  },
  {
    headerName: 'Daily Budget',
    field: 'defaultDailyBudget',
    filter: 'agNumberColumnFilter',
    width: 90,
    editable: !isExternalUser,
    cellStyle: (params: any) => {
      if (validateDefaultDailyBudget(params.value)) {
        return { color: 'white', background: '#ff4c6a' };
      } else {
        return { color: 'inherit', background: 'inherit' };
      }
    },
    valueFormatter: (params: any) => params.value ? params.value : userDefaultConfiguration.defaultDailyBudget,
    onCellValueChanged: onCellValueChanged,
  },
  {
    headerName: 'Custom Pixel',
    field: 'customPixel',
    filter: 'agTextColumnFilter',
    width: 180,
    filterParams: { newRowsAction: 'keep' },
  },
  {
    headerName: 'Conversion Events',
    field: 'conversionEvent',
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    headerName: 'Interests',
    field: 'interest',
    filter: 'agTextColumnFilter',
    cellRenderer: (params: any) => {
      let interestDisplay = '';
      if (params.value != null && params.value.category) {
        interestDisplay += params.value.category + ' > ';
      }
      if (params.value != null && params.value.name) {
        interestDisplay += params.value.name;
      } else {
        interestDisplay = '';
      }
      return interestDisplay;
    },
    width: 100,
  },
  {
    headerName: 'Ad Delivery Optimization',
    field: 'optimizationGoal',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'ROAS',
    field: 'roas',
    filter: 'agNumberColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Bid Strategy',
    field: 'bidStrategy',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Gender',
    field: 'gender',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Age',
    field: 'age',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Audiences',
    field: 'audiences.name',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Paused Campaign',
    field: 'pausedCampaign',
    filter: 'agTextColumnFilter',
    cellRenderer: (params: any) => params.value ? 'v' : '',
    width: 300,
    hide: true,
  },
  {
    headerName: 'Target Language',
    field: 'languageSet',
    filter: 'agTextColumnFilter',
    width: 300,
    hide: true,
    cellRenderer: (params: any) => params.value ? 'v' : '',
  },
  {
    headerName: 'Start on (EST)',
    field: 'startOn',
    filter: 'agTextColumnFilter',
    hide: true,
    width: 100,
  },
  {
    headerName: 'Dynamic Creative',
    field: 'dynamicCreative',
    filter: 'agTextColumnFilter',
    cellRenderer: (params: any) => params.value ? 'v' : '',
    width: 300,
    hide: true,
  },
  {
    headerName: 'Tonic Campaign',
    field: 'search.tonic',
    filter: 'agNumberColumnFilter',
    width: 90,
    cellRenderer: (params: any) => params.data.isSearch && params.value.campaignId ? tonicCampaignsList?.filter(campaign => campaign.value.toString() === params.value.campaignId)[0].content : null,
  },
  {
    headerName: 'Campaign Offer',
    field: 'search',
    filter: 'agNumberColumnFilter',
    width: 90,
    cellRenderer: (params: any) => params.data.isSearch && params.data.search.searchProvider === SearchProvider.ExploreAds ? params.value[SearchProvider.ExploreAds].campaignOffer : null,
  },
  {
    headerName: 'Identifier',
    field: 'search',
    filter: 'agNumberColumnFilter',
    width: 90,
    cellRenderer: (params: any) => params.data.isSearch && (params.data.search.searchProvider === SearchProvider.ExploreAds || params.data.search.searchProvider === SearchProvider.AFS) ? params.value[params.value.searchProvider].identifier : null,
  },
];
