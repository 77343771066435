/** @jsxImportSource @emotion/react */
import { Button, ButtonType, Dropdown, DropdownListItemProps, IconButton, Input, Modal, RowData, Text } from '@ftbpro/mm-admin-ui-components';
import { DeleteIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/react';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import React, { SyntheticEvent, useState } from 'react';
import { Configuration } from '../../../../../../../../../../configurations/configurations.types';
import { useRecoilValue } from 'recoil';
import { campaignsListState } from '../../../../../../createCampaign.selector';

const bulkOperationsItems = [
  {
    content: 'Set CPC',
    value: 'setCPC',
  },
  {
    content: 'Set Name',
    value: 'setName',
  },
  {
    content: 'Adjust CPC by cents',
    value: 'adjustCPCByCents',
  },
  {
    content: 'Adjust CPC by percentage',
    value: 'adjustCPCByPercentage',
  },
  {
    content: 'Set Daily Budget',
    value: 'setDailyBudget',
  },
];

const bulkUpdateSettings = {
  setName: { column: 'freeTextName' },
  setCPC: { column: 'defaultBid' },
  adjustCpcByCents: { column: 'defaultBid' },
  adjustCpcByPercentage: { column: 'defaultBid' },
  setDailyBudget: { column: 'defaultDailyBudget' },
};

const styles = {
  wrapper: css({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: '24px',
    paddingRight: '24px',
  }),
  modal: {
    footer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: '24px',
    },
    content: {
      height: '100%',
    },
  },
  buttonsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
  }),
};

const variables = {
  modal: {
    modalWidth: '348px',
    headerMargin: '0px',
  },
};

interface BulkOperationsProps {
  selectedCampaignsIds: number[];
  updateCellValue: (newValue: string | number, field: string, data: RowData) => void;
  onDeleteClick: (id: number) => void;
  userDefaultConfiguration: Configuration;
}

export const BulkOperations = ({ selectedCampaignsIds, updateCellValue, onDeleteClick, userDefaultConfiguration }: BulkOperationsProps) => {
  const campaignList = useRecoilValue(campaignsListState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState<DropdownListItemProps | null>(null);

  const onBulkDeleteClick = () => {
    selectedCampaignsIds.forEach((campaignId) => {
      onDeleteClick(campaignId);
    });
  };
  const onBulkOperationSelected = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setSelectedOperation(selectedItem);
    setIsModalOpen(true);
  };


  const onUpdateClick = (value: string | number) => {
    setIsModalOpen(false);
    let newValue: string | number = '';
    let oldValue: number;
    let field: string = '';

    selectedCampaignsIds.forEach((campaignId) => {
      const campaign = campaignList.find((campaign) => campaign.id === campaignId);
      switch (selectedOperation?.value) {
        case 'setCPC':
        case 'setName':
        case 'setDailyBudget':
          field = bulkUpdateSettings[selectedOperation?.value].column;
          newValue = value;
          break;
        case 'adjustCPCByCents':
          field = 'defaultBid';
          oldValue = campaign[field] ? Number(campaign[field]) : userDefaultConfiguration.defaultBid;
          newValue = Math.round((Number(oldValue) + Number(value) / 100) * 1000) / 1000;
          break;
        case 'adjustCPCByPercentage':
          field = 'defaultBid';
          oldValue = campaign[field] ? Number(campaign[field]) : userDefaultConfiguration.defaultBid;
          newValue = Math.round((Number(campaign[field]) + campaign[field] * (Number(value) / 100)) * 1000
          ) / 1000;
          break;
        default:
          alert('operation not supported');
          break;
      }
      updateCellValue(newValue, field, campaign);
    });
    setSelectedOperation(null);
  };

  return (
    <>
      {isModalOpen &&
       <BulkOperationModal
         title={selectedOperation?.content as string || ''}
         setIsModalOpen={setIsModalOpen}
         onUpdateClick={onUpdateClick}
         setSelectedOperation={setSelectedOperation}
       />
      }
      <div css={styles.wrapper}>
        <IconButton
          type={ButtonType.Primary}
          icon={DeleteIcon}
          onClick={onBulkDeleteClick}
          disabled={selectedCampaignsIds.length === 0}
        />
        <Dropdown
          items={bulkOperationsItems}
          placeholder="Bulk Operations"
          onSelectedChange={onBulkOperationSelected}
          disabled={selectedCampaignsIds.length === 0}
          doNotShowSelectedValue={true}
          selectedValue={selectedOperation?.value}
        />
      </div>
    </>);
};

interface BulkOperationsModalProps {
  title: string,
  setIsModalOpen: (isOpen: boolean) => void;
  onUpdateClick: (value: string) => void;
  setSelectedOperation: (selectedOperation: DropdownListItemProps | null) => void;
}

const BulkOperationModal = ({ title, setIsModalOpen, onUpdateClick, setSelectedOperation }: BulkOperationsModalProps) => {
  const [value, setValue] = useState<string>('');

  const onValueChange = (e: SyntheticEvent<HTMLElement, Event>, data?: string | undefined) => {
    setValue(data || '');
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    setSelectedOperation(null);
  };

  return (
    <Modal
      title={''}
      open
      closeable={false}
      style={styles.modal}
      variables={variables.modal}
      footer={(
        <div css={styles.buttonsContainer}>
          <Button
            content="Cancel"
            onClick={onCancelClick}
            type={ButtonType.Secondary}
          />
          <Button
            content="OK"
            onClick={() => onUpdateClick(value)}
          />
        </div>
      )}
    >
      <Input
        value={value}
        onChange={onValueChange}
        textType={Text.TEXT_TYPES.TITLE_S}
        labelProps={{ text: title }}
        placeholder={title}
      />
    </Modal>
  );
};
