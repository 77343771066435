import { selector } from "recoil";
import { CampaignsServiceProvider } from "../../../../../../../../../../../../../services/campaigns/CampaignsServiceProvider";

export const tonicCampaigns = selector({
  key: 'tonicSelector',
  get: async () => {
    try {
      const response = await CampaignsServiceProvider.getTonicCampaign();
      const formattedResponse = response.map((campaign: { id: string, name: string }) => ({
        value: campaign.id, content: campaign.name
      }));
      return formattedResponse;
    } catch (error) {
      const errorMessage = 'Error fetching tonic campaigns:';
      throw new Error(errorMessage);
    }
  },
});
