/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, ButtonType, CssTooltip, TOOLTIP_POSITIONS } from '@ftbpro/mm-admin-ui-components';
import { css } from "@emotion/react";
import { CampaignsListTable } from './components/CampaignsListTable/CampaignsListTable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { campaignsDraftTriggeredState, campaignsListState } from '../../createCampaign.selector';
import { validateCampaigns } from './campaignsList.validations';
import { ErrorsTooltipContent } from '../../../../../../../components/ErrorsTooltipContent/ErrorsTooltipContent';
import { CampaignsServiceProvider } from '../../../../../../../services/campaigns/CampaignsServiceProvider';
import { targetState } from '../SettingsAndTarget/components/Target/targetState/target.atom';
import { useParams } from 'react-router-dom';
import { settingsState } from '../SettingsAndTarget/components/Settings/settingsState/settings.atom';
import { useNavigateToPage } from '../../../../../../../hooks/useNavigate';

const styles = {
  container: {
    flexGrow: 3,
  },
  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "right",
    padding: "16px 20px",
    gap: '8px',
    height: '52px',
  }),
  total: {
    marginRight: "8px",
  },
  tooltip: {
    zIndex: 4,
  }
}

const variables = {
  button: {
    width: "100px",
  }
}

export const CampaignsListContainer = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const target = useRecoilValue(targetState);
  const settings = useRecoilValue(settingsState);
  const { isDynamicCreative } = target;
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsListState);
  const [errors, setErrors] = useState<string[]>([]);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const { group_name: groupName } = useParams();
  const navigate = useNavigateToPage();

  useEffect(() => {
    const errors = validateCampaigns(campaignsList);
    if (errors.length > 0) {
      setErrors(errors);
      setDisabledSubmit(true);
    } else {
      setErrors([]);
      setDisabledSubmit(false);
    }
  }, [campaignsList]);

  const onClearClick = () => {
    setTriggerState({ loading: false, triggered: false, error: '' });
    setCampaignsList([]);
  };

  const onSubmit = async () => {
    try {
      setTriggerState({ triggered: true, loading: true, error: '' });
      type DomainsAndAccountsMap = {
        [key: string]: string[]; // Domain as the key, array of accounts as the value
      };

      const transformedData = settings.accountsDomains.reduce<DomainsAndAccountsMap>((result, item) => {
        result[item.domain] = item.accounts;
        return result;
      }, {} as DomainsAndAccountsMap);
      const payload = {
        drafts: campaignsList,
        articleGroup: groupName || '',
        DomainsAndAccounts: transformedData,
      };
      await CampaignsServiceProvider.submitCampaignsDraft(payload);
      const redirect = `/pages/campaignsCreator/group-add-creatives/' ${groupName} + '/facebook/${isDynamicCreative ? 'dynamic' : ''}`;
      navigate(redirect);
    } catch (error) {
      const errorMessage = 'Error generating campaigns drafts';
      setTriggerState({ triggered: true, loading: false, error: errorMessage });
    }
  };


  return (
        <div css={styles.container}>
            <div css={styles.buttonContainer}>
              <Button type={ButtonType.Secondary} variables={variables.button} onClick={onClearClick}>Clear</Button>
              <CssTooltip
                content={errors.length > 0 ? <ErrorsTooltipContent errors={errors}/> : ''}
                style={styles.tooltip as any}
                position={TOOLTIP_POSITIONS.BOTTOM_LEFT}
                variables={{
                  tooltipMultilineWidth: 'fit-content',
                }}
              >
                <Button type={ButtonType.Primary} variables={variables.button} disabled={disabledSubmit} onClick={onSubmit}>Submit</Button>
              </CssTooltip>
            </div>
            <CampaignsListTable/>
        </div>
    );
}
